.slick-slider {
    .slick-arrow {
        background: transparent;
        z-index: 9;
        color: initial;
        font-size: medium;
        line-height: normal;

        &::before {
            content: none;
        }
    }

    .slick-dots {
        bottom: -20px;

        @media (max-width: 768px) {
            bottom: -15px;
        }

        & > li {
            content: none;
            margin: 0;
            margin-left: 6px;
            width: 4px;
            height: auto;

            &:first-child {
                margin-left: 0;
            }
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -ms-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;

            & > button {
                width: 4px;
                height: 4px;
                padding: 0;
                content: none;
                border-radius: 6.25rem;
                background-color: #9A9DA3;
                &::before {
                    content: none !important;
                }
            }
            
            &.slick-active {
                width: 14px;
                & > button {
                    width: 14px;
                    height: 4px;
                    background-color: #5104E1 !important;
                }
            }
        }
    }
}

.slider-news {
    & > .slick-list {
        & > .slick-track {
            display: flex;
            & > .slick-slide {
                height: auto;
                & > div {
                    height: 100%;
                }
            }
        }
    }

}

.product-pictures {
    .slick-dots {
        bottom: -20px;
        text-align: start;
        & > li {
            &.slick-active {
                & > button {
                    background-color: #5104E1;
                }
            }
            & > button {
                background-color: #bdbdbd;
            }
        }
    }

    & > .slick-dots.slick-thumb {
        position: relative;
        bottom: -5px;
        & > li {
            width: 50px;
            height: 50px;
            position: relative;
            &.slick-active {
                transition: none;
                & > a {
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        height: 4px;
                        width: 100%;
                        background-color: #5104E1;
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }

            & > a {
                display: block;
                width: 50px;
                height: 50px;
                position: relative;
                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

